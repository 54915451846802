import { parseReservationDate } from '~/utils/utc-date'
import type { ReservationBreadcrumbItem } from '~/types/reservation'
import { InternalRouteName } from '~/constants/internal-route-name'

export default function useReservationParams() {
    const runtimeConfig = useRuntimeConfig()
    const route = useRoute()
    const localDateTimeFormatter = new Intl.DateTimeFormat('fr-FR', {
        dateStyle: 'full',
        timeStyle: 'short',
        timeZone: 'Europe/Paris',
    })
    const localDateFormatter = new Intl.DateTimeFormat('fr-FR', {
        dateStyle: 'medium',
        timeStyle: undefined,
        timeZone: 'Europe/Paris',
    })

    const rentalId = computed(() => {
        return route.params?.id as string | undefined
    })

    const category = computed<string | undefined>(() => {
        return route.params?.category as string | undefined
    })
    const departureAgency = computed<string | undefined>(() => {
        return route.params?.depAgency as string | undefined
    })
    const returnAgency = computed(() => {
        return (route.params?.retAgency as string | undefined) || departureAgency.value
    })
    const vehicleCode = computed(() => {
        return route.params?.vehicle as string | undefined
    })
    const departureTime = computed(() => {
        return parseReservationDate(route.params?.depTime as string | undefined)
    })
    const returnTime = computed(() => {
        return parseReservationDate(route.params?.retTime as string | undefined)
    })

    if (departureTime.value && returnTime.value && departureTime.value >= returnTime.value) {
        throw createError({
            statusCode: 400,
            statusMessage: 'Return time must be greater than departure time',
            fatal: true,
        })
    }

    // isDepartureDateValid() cannot be used anymore because the values for validation are now dynamic.
    // There are fetched from the API.
    // if (departureTime.value && !isDepartureDateValid(departureTime.value)) {
    //     throw createError({
    //         statusCode: 410,
    //         statusMessage: 'Departure time must be at least 24 hours in the future',
    //         fatal: true,
    //     })
    // }

    const agencyLink = computed(() => {
        return getAgencyLink(route.params.depAgency as string)
    })

    const getAgencyLink = (agencyCode: string) => {
        return (
            '/reservation/'
            + route.params.category
            + '-'
            + agencyCode
            + '-'
            + route.params.depTime
            + '-'
            + route.params.retTime
        )
    }

    const getAgencyRoute = (agencyCode?: string) => {
        const { category, depTime, retTime, depAgency } = route.params
        if (!category || !depTime || !retTime || !depAgency) return

        return {
            name: InternalRouteName.RESERVATION_LISTING,
            params: {
                category,
                depTime,
                retTime,
                depAgency: agencyCode || depAgency,
            },
        }
    }

    const vehicleLink = computed<string | undefined>(() => {
        if (!route.params.vehicle) {
            return undefined
        }
        return getVehicleLink(route.params.vehicle as string)
    })

    const accessoriesLink = computed<string | undefined>(() => {
        if (!route.params.vehicle) {
            return undefined
        }
        return getVehicleLink(route.params.vehicle as string) + '/accessoires'
    })

    const summaryLink = computed<string | undefined>(() => {
        if (!route.params.vehicle) {
            return undefined
        }
        return getVehicleLink(route.params.vehicle as string) + '/recapitulatif'
    })

    const formattedDepartureTime = computed(() => {
        return localDateTimeFormatter.format(departureTime.value)
    })

    const formattedReturnTime = computed(() => {
        return localDateTimeFormatter.format(returnTime.value)
    })

    const formattedDepartureDate = computed(() => {
        return localDateFormatter.format(departureTime.value)
    })

    const formattedReturnDate = computed(() => {
        return localDateFormatter.format(returnTime.value)
    })

    const daysCount = computed(() => {
        if (!returnTime.value || !departureTime.value) {
            return 0
        }
        return Math.ceil((returnTime.value.getTime() - departureTime.value.getTime()) / (1000 * 60 * 60 * 24))
    })

    const maxKilometers = computed(() => {
        return runtimeConfig.public.myRentCar.maxKilometersCount || 5000
    })

    const supportAccessories = computed(() => {
        return (
            route.params.category
            && typeof route.params.category === 'string'
            && route.params.category.toLowerCase() !== runtimeConfig.public.myRentCar.personalVehicleCode.toLowerCase()
        )
    })

    const { accessoriesRoute, optionsRoute, listingRoute, summaryRoute } = useReservationLink()

    const breadcrumbItems = computed(() => {
        const items: ReservationBreadcrumbItem[] = [
            {
                name: 'vehicle_choice',
                link: agencyLink.value,
                route: listingRoute.value,
            },
            {
                name: 'vehicle_options',
                link: vehicleLink.value,
                route: optionsRoute.value,
            },
        ]

        if (supportAccessories.value) {
            items.push({
                name: 'accessories',
                link: accessoriesLink.value,
                route: accessoriesRoute.value,
            })
        }

        items.push({
            name: 'summary',
            link: summaryLink.value,
            route: summaryRoute.value,
        })

        items.push({
            name: 'payment',
            link: rentalId?.value && '/reservation/' + rentalId.value + '/confirmation',
        })

        return items
    })

    const getVehicleLink = (vehicle: string, agencyCode?: string) => {
        const localAgencyLink = agencyCode ? getAgencyLink(agencyCode) : agencyLink.value
        return localAgencyLink + '-' + vehicle
    }

    return {
        category,
        departureAgency,
        returnAgency,
        departureTime,
        formattedDepartureTime,
        formattedDepartureDate,
        returnTime,
        formattedReturnTime,
        formattedReturnDate,
        vehicleCode,
        breadcrumbItems,
        agencyLink,
        vehicleLink,
        accessoriesLink,
        summaryLink,
        maxKilometers,
        daysCount,
        supportAccessories,
        getAgencyRoute,
    }
}
